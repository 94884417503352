// assets
import { IconBrain, IconBulb, IconListDetails } from '@tabler/icons';

// constant
const icons = { IconBrain, IconBulb, IconListDetails };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const menu = {
    id: 'menu',
    type: 'group',
    children: [
        {
            id: 'mentor',
            title: 'Quero Mentorar',
            type: 'item',
            url: '/mentor',
            icon: icons.IconBrain,
            breadcrumbs: false
        },
        {
            id: 'mentorado',
            title: 'Quero Receber Mentoria',
            type: 'item',
            url: '/mentorado',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'mentores-mentorados',
            title: 'Mentores e Mentorados',
            type: 'item',
            url: '/mentores-mentorados',
            icon: icons.IconListDetails,
            breadcrumbs: false
        }
    ]
};

export default menu;
