import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const MentorPage = Loadable(lazy(() => import('views/mentor')));
const MentoradoPage = Loadable(lazy(() => import('views/mentorado')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'mentor',
            element: <DashboardDefault />
        },
        {
            path: 'mentorado',
            element: <MentoradoPage />
        },
        {
            path: 'mentores-mentorados',
            element: <MentorPage />
        }
    ]
};

export default AuthenticationRoutes;
